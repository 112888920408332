import React from 'react'
import '../Styles/Footer.css'

function Footer(){
    return(
        <footer>

        </footer>   
    )
}

export default Footer;